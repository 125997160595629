import React, { useEffect, useRef, useState } from 'react';
import { makePrioStyles } from '../../../theme/utils';
import { Typography } from 'antd';
import {
  Button,
  Modal,
  PrioSpinner,
  useTheme,
} from '@prio365/prio365-react-library';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextArea from 'antd/lib/input/TextArea';
import { PrioTheme } from '../../../theme/types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  getContactsByIdState,
  getUserMeContactId,
} from '../../../apps/main/rootReducer';
import { MailComment } from '../../../models/Message';
import {
  createComment,
  deleteComment,
  updateComment,
} from '../../mail/actions/projects/mailCommentActions';
import UserAvatar from '../../../components/UserAvatar';
import { updateMessage } from '../../mail/actions/actionControllers/messageActionController';
import { ProjectId } from '../../../models/Types';

const useStyles = makePrioStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  commentsContainer: {
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing.regular,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.regular,
  },
  commentContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing.small,
  },

  commentBox: {
    alignItems: 'flex-start',
    padding: theme.spacing.regular,
    borderRadius: '0px 8px 8px 8px',
    width: '100%',
    fontSize: '14px',
  },

  titleContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },

  dateText: {
    fontSize: '12px',
    fontWeight: 400,
    textAlign: 'right',
    color: theme.colors.application.typography.default,
  },

  commentActions: {
    display: 'none',
    justifyContent: 'flex-end',
  },

  commentContainerHover: {
    '&:hover $commentActions': {
      display: 'flex',
    },
  },

  commentEditActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  newCommentContainer: {
    display: 'flex',
    padding: theme.spacing.regular,
    flexDirection: 'column',
    gap: theme.spacing.small,
  },
  textAreaContainer: {
    display: 'flex',
    backgroundColor: '#fff',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing.small,
  },
  textAreaField: {
    padding: theme.spacing.regular,
    height: '116px !important',
    border: '1px solid #E0E6EE',
    borderRadius: '2px',
    background: '#FFF',
    color: theme.colors.application.typography.default,
  },

  noComments: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'center',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
  },
}));

interface MailCommentProps {
  messageId: string;
  sharedMailboxId?: string;
  comments: MailComment[];
  isFetching: boolean;
  emailConversationId: string;
  projectId: ProjectId;
  setEmailConversationId: (emailConversationId: string) => void;
}

const MailComments: React.FC<MailCommentProps> = (props) => {
  //#region ------------------------------ Defaults
  const {
    messageId,
    sharedMailboxId,
    comments,
    isFetching,
    emailConversationId,
    projectId,
    setEmailConversationId,
  } = props;
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [newComment, setNewComment] = useState('');
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [commentToDelete, setCommentToDelete] = useState<string | null>(null);
  const [editingCommentId, setEditingCommentId] = useState<string | null>(null);
  const [editingCommentText, setEditingCommentText] = useState<string>('');
  const lastCommentRef = useRef<HTMLDivElement | null>(null);
  const [originalCommentText, setOriginalCommentText] = useState<string>('');

  const userId = useSelector(getUserMeContactId);
  const contactsById = useSelector(getContactsByIdState);
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const handleAddComment = async (
    event?: React.KeyboardEvent<HTMLTextAreaElement>
  ) => {
    if (event) {
      event.preventDefault();
    }
    if (newComment.trim() !== '') {
      const comment: MailComment = {
        messageId: messageId,
        comment: newComment,
        sharedMailboxId: sharedMailboxId ?? '',
      };
      const addedCommentEmailConversationId = await (dispatch as any)(
        createComment(comment)
      );
      if (!emailConversationId && addedCommentEmailConversationId) {
        setEmailConversationId(addedCommentEmailConversationId);

        dispatch(
          updateMessage(projectId, messageId, {
            customSingleValueExtendedProperties: {
              emailConversationId: addedCommentEmailConversationId,
            },
          })
        );
      }
      setNewComment('');
      scrollToLastComment();
    }
  };

  const startEditing = (comment: MailComment) => {
    setEditingCommentId(comment.emailCommentId);
    setEditingCommentText(comment.comment);
    setOriginalCommentText(comment.comment);
  };

  const saveEditedComment = (id: string) => {
    if (editingCommentText !== originalCommentText) {
      const updatedComment: MailComment = {
        emailCommentId: id,
        messageId: messageId,
        comment: editingCommentText,
        sharedMailboxId: sharedMailboxId ?? '',
      };
      dispatch(updateComment(updatedComment));
    }

    setEditingCommentId(null);
    setEditingCommentText('');
    setOriginalCommentText('');
  };

  const cancelEditing = () => {
    setEditingCommentId(null);
    setEditingCommentText('');
  };

  const showDeleteConfirm = (id: string) => {
    setCommentToDelete(id);
    setIsModalVisible(true);
  };

  const handleDeleteComment = (id: string) => {
    dispatch(deleteComment(id));
    setIsModalVisible(false);
  };

  const formatDate = (dateString) => {
    if (!dateString.endsWith('Z')) {
      dateString += 'Z';
    }
    const date = new Date(dateString);
    return `${date.toLocaleDateString()} | ${date
      .toLocaleTimeString()
      .slice(0, -3)}`;
  };
  // #endregion

  //#region ------------------------------ Effects
  const scrollToLastComment = () => {
    lastCommentRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'end',
    });
  };

  useEffect(() => {
    scrollToLastComment();
  }, [emailConversationId]);
  //#endregion

  return (
    <div className={classes.root}>
      <div className={classes.commentsContainer}>
        <Typography.Title
          level={3}
          style={{
            color: theme.colors.application.typography.default,
            margin: 0,
          }}
        >
          {t('common:widgetArea.comments.title')}
        </Typography.Title>{' '}
        {isFetching && emailConversationId && (
          <div className={classes.noComments}>
            <PrioSpinner size="large" />
          </div>
        )}
        {!isFetching && !emailConversationId && (
          <div className={classes.noComments}>
            <p style={{ color: theme.colors.application.typography.default }}>
              {t('common:widgetArea.comments.noComments')}
            </p>
          </div>
        )}
        {!isFetching &&
          emailConversationId &&
          comments?.map((comment, index) => {
            const isLastComment = index === comments?.length - 1;
            const title = (
              <div className={classes.titleContainer}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: theme.spacing.small,
                  }}
                >
                  <UserAvatar
                    contact={contactsById[comment.createdBy]}
                    backgroundColor="#1a2f41"
                    size={'medium'}
                  ></UserAvatar>
                  <Typography
                    style={{
                      color: theme.colors.application.typography.default,
                      fontWeight: 600,
                    }}
                  >
                    {contactsById[comment.createdBy]?.firstName +
                      ' ' +
                      contactsById[comment.createdBy]?.lastName}
                  </Typography>
                </div>
                {comment.createdBy === userId &&
                  editingCommentId !== comment.emailCommentId && (
                    <div className={classes.commentActions}>
                      <FontAwesomeIcon
                        icon={['fal', 'pen']}
                        style={{
                          padding: theme.spacing.small,
                          cursor: 'pointer',
                          color: '#0052CC',
                        }}
                        onClick={() => startEditing(comment)}
                      />
                      <FontAwesomeIcon
                        icon={['fal', 'trash']}
                        style={{
                          padding: theme.spacing.small,
                          cursor: 'pointer',
                          color: 'red',
                        }}
                        onClick={() =>
                          showDeleteConfirm(comment.emailCommentId)
                        }
                      />
                    </div>
                  )}
              </div>
            );

            return (
              <div
                className={`${classes.commentContainer} ${classes.commentContainerHover}`}
              >
                <div>{title}</div>
                <div
                  key={comment.emailCommentId}
                  ref={isLastComment ? lastCommentRef : null}
                  className={classes.commentBox}
                  style={{
                    backgroundColor:
                      comment.createdBy === userId
                        ? theme.colors.base.blue[30]
                        : '#EEF3FA',
                  }}
                >
                  <Typography
                    style={{
                      color: theme.colors.application.typography.default,
                    }}
                  >
                    {editingCommentId === comment.emailCommentId ? (
                      <>
                        <TextArea
                          value={editingCommentText}
                          onChange={(e) =>
                            setEditingCommentText(e.target.value)
                          }
                          onPressEnter={() =>
                            saveEditedComment(comment.emailCommentId)
                          }
                          autoSize={{ minRows: 3, maxRows: 10 }}
                          style={{
                            color: theme.colors.application.typography.default,
                            fontSize: '14px',
                          }}
                        />
                        <div className={classes.commentEditActions}>
                          <FontAwesomeIcon
                            icon={['fal', 'check']}
                            style={{ marginLeft: '20px', cursor: 'pointer' }}
                            onClick={() =>
                              saveEditedComment(comment.emailCommentId)
                            }
                          />
                          <FontAwesomeIcon
                            icon={['fal', 'times']}
                            style={{ marginLeft: '20px', cursor: 'pointer' }}
                            onClick={cancelEditing}
                          />
                        </div>
                      </>
                    ) : (
                      comment.comment
                    )}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.dateText}>
                    {comment.isEdited &&
                      ` ${t('common:widgetArea.comments.edited')} `}
                    {formatDate(comment.createdAt)}
                  </Typography>
                </div>
              </div>
            );
          })}
      </div>
      <div className={classes.newCommentContainer}>
        <div className={classes.textAreaContainer}>
          <TextArea
            placeholder={t('common:widgetArea.comments.newCommentPlaceholder')}
            autoSize
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            className={classes.textAreaField}
          />
        </div>
        <div className={classes.buttonsContainer}>
          <span>{/* hier kommt später paperclip icon */}</span>
          <Button
            type="primary"
            style={{}}
            iconProp={['fal', 'paper-plane-top']}
            onClick={() => handleAddComment()}
            disabled={newComment.trim() === '' || isFetching}
            loading={isFetching}
          >
            {t('common:widgetArea.comments.addCommentButton')}
          </Button>
        </div>
      </div>
      <Modal
        title={t('common:widgetArea.comments.deleteModal.title')}
        visible={isModalVisible}
        onOk={() => handleDeleteComment(commentToDelete)}
        onClose={() => setIsModalVisible(false)}
        okText={t('common:widgetArea.comments.deleteModal.okButton')}
        cancelText={t('common:widgetArea.comments.deleteModal.cancelButton')}
      >
        {t('common:widgetArea.comments.deleteModal.message')}
      </Modal>
    </div>
  );
};

export default MailComments;
