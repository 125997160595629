import { Reducer } from 'react';
import { CLEAR_PRIO_CACHE } from '../../../actions';
import {
  FETCH_SEARCH_CONFIG_COMMIT,
  FETCH_SEARCH_CONFIG_REQUEST,
  FETCH_SEARCH_CONFIG_ROLLBACK,
} from '../actions';
import { combineReducers } from 'redux';
import { FilterBarData, SearchType } from '../types';
import { DispatchAction } from '../../../models/Redux';
import moment from 'moment';

export type BySearchType = Partial<Record<SearchType, FilterBarData>>;

export const initialState = {
  searchConfigBySearchType: {} as BySearchType,
  meta: { isFetchingSearchParameters: false },
};

const searchConfigBySearchType: Reducer<
  BySearchType,
  DispatchAction<{ searchType: SearchType }>
> = (state = initialState.searchConfigBySearchType, action) => {
  switch (action.type) {
    case FETCH_SEARCH_CONFIG_COMMIT: {
      const { payload } = action;
      const { searchType } = action.meta;

      return {
        ...state,
        [searchType]: {
          ...payload,
          fetchDate: moment().format('YYYY-MM-DD hh:mm:ss'),
        },
      };
    }
    case CLEAR_PRIO_CACHE: {
      return initialState.searchConfigBySearchType;
    }
    default:
      return state;
  }
};

interface FilterMeta {
  isFetchingSearchParameters: boolean;
}

const meta: Reducer<FilterMeta, any> = (state = initialState.meta, action) => {
  switch (action.type) {
    case FETCH_SEARCH_CONFIG_REQUEST: {
      return {
        ...state,
        isFetchingSearchParameters: true,
      };
    }
    case FETCH_SEARCH_CONFIG_COMMIT: {
      return {
        ...state,
        isFetchingSearchParameters: false,
      };
    }
    case FETCH_SEARCH_CONFIG_ROLLBACK: {
      return {
        ...state,
        isFetchingSearchParameters: false,
      };
    }
    default:
      return state;
  }
};

export interface FilterReducerState {
  searchConfigBySearchType: BySearchType;
  meta: FilterMeta;
}

export default combineReducers<FilterReducerState>({
  searchConfigBySearchType,
  meta,
});

export const getSearchConfigBySearchType: (
  state: FilterReducerState,
  searchType: SearchType
) => FilterBarData = (state, searchType) => {
  return state.searchConfigBySearchType[searchType];
};

export const getIsFetchingSearchConfig: (
  state: FilterReducerState
) => boolean = (state) => state.meta.isFetchingSearchParameters;
