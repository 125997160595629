import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  AutoComplete,
  Typography,
  FormInstance,
} from 'antd';
import { useTranslation } from 'react-i18next';

import { makePrioStyles } from '../../../theme/utils';

import { useSelector, useDispatch } from 'react-redux';
import {
  getTitleSuggestions,
  getUserMe,
  RootReducerState,
} from '../../../apps/main/rootReducer';
import Flex from '../../../components/Flex';
import { fetchTitleSuggestions } from '../../settings/actions/titleSuggestions';
import { TitleSuggestion } from '../../../models/TitleSuggestion';
import { fallBackTitleOptions } from '../../contacts/components/ContactForm';
import { rowGutter, colon } from '../../../util/forms';
import { defaultPhonePattern } from '../../../util';
import {
  AddEmployee,
  allFormOfEmploymentTypes,
  FormOfEmployment,
} from '../../../models/Employee';
import OfficePicker from '../../companies/components/OfficePicker';
import ContactPicker from '../../contacts/components/ContactPicker';
import { Configuration } from '../../../models/Configuration';
import { ConfigurationKeys, EMailSuffix } from '../../../models/Types';
import Checkbox from 'antd/lib/checkbox/Checkbox';
import {
  apiFetchConfigurations,
  apiFetchEmailSuffixes,
} from '../../settings/api';
import moment from 'moment';
import { apiValidateEmail } from '../../users/api';
import { useTheme } from 'react-jss';
import { PrioTheme } from '../../../theme/types';
import CustomSingleDatePicker from '../../../components/CustomSingleDatePicker';
import classNames from 'classnames';
import { Divider } from '@prio365/prio365-react-library';
import {
  parseFalsyValuesToUndefined,
  removeFalsyValuesFromObject,
} from '../utils';
import eqauls from 'deep-equal';
import DebouncedInputNumber from '../../../components/DebouncedInputField/DebouncedInputNumber';

const useStyles = makePrioStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: 1024,
    height: '100%',
  },
  submitButtonFormItem: {
    textAlign: 'right',
    marginBottom: 0,
  },
  actionButtonsRow: {
    marginTop: theme.old.spacing.unit(2),
  },
  column: {
    height: '100%',
  },
  tabs: {},
  tabsPane: {},
  cancelButton: {
    marginTop: theme.old.spacing.unit(2),
  },
  fullWidth: {
    width: '100%',
  },
  scrollable: {
    height: 'calc(100% - 0px)',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  eMailSuffixSelect: {
    '&:before': {
      content: '"@"',
      display: 'inline-block',
      position: 'absolute',
      left: -22,
      top: 3,
      color: theme.old.typography.colors.muted,
    },
  },
  heading: {
    marginBottom: theme.old.spacing.unit(2),
    marginTop: -theme.old.spacing.unit(1),
  },
  headingFirst: {
    marginBottom: theme.old.spacing.unit(2),
  },
  // divider: {
  //   marginTop: '0px',
  // },
  checkboxWeekdays: {
    marginBottom: theme.old.spacing.unit(2),
  },
  checkboxWeekend: {
    marginBottom: theme.old.spacing.unit(1),
  },
  labelHidden: {
    '& > div > label': {
      visibility: 'hidden',
    },
  },
}));

interface EmployeeFormProps {
  setDisableActionButton: (boolean) => void;
  disableForm?: boolean;
  actionLabel: string;
  onFinishCreate: (value: AddEmployee) => Promise<boolean>;
  form?: FormInstance<AddEmployee>;
}

const emptyEmployee: AddEmployee = {
  formOfEmployment: 'fulltime',
  hoursPerWeek: 40,
  holidayEntitlement: 24,
  workingDayMonday: true,
  workingDayTuesday: true,
  workingDayWednesday: true,
  workingDayThursday: true,
  workingDayFriday: true,
  workingDaySaturday: false,
  workingDaySunday: false,
  templateContactId: '',
};

export const EmployeeForm: React.FC<EmployeeFormProps> = (props) => {
  //#region ------------------------------ Defaults
  const classes = useStyles();
  const theme = useTheme<PrioTheme>();
  const {
    onFinishCreate,
    setDisableActionButton,
    disableForm: disabled,
    form,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  //#endregion

  //#region ------------------------------ States / Attributes / Selectors
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isFormChanged, setisFormChanged] = useState<boolean>(false);
  const [isCalendarViewOpen, setIsCalendarViewOpen] = useState<boolean>(false);

  const [emailSuffixes, setEmailSuffixes] = useState<EMailSuffix[]>([]);
  const [loadSuffixes, setLoadSuffixes] = useState<boolean>(false);
  const [emailValidating, setEmailValidating] = useState<boolean>(false);
  const [configurations, setConfigurations] = useState<Configuration[] | null>(
    null
  );

  const titleSuggestions: TitleSuggestion[] = useSelector<
    RootReducerState,
    TitleSuggestion[]
  >(getTitleSuggestions);

  const userMe = useSelector(getUserMe);

  const titleOptions = titleSuggestions.map((suggestion) => ({
    value: suggestion.value,
  }));

  const disableForm = disabled || isSaving;
  //#endregion

  //#region ------------------------------ Methods / Handlers
  const formatter: (
    value: number,
    info: { userTyping: boolean; input: string }
  ) => string = (value, info) => {
    let _value = value;
    if (!info.userTyping) {
      _value = Math.round(value * 4) / 4;
    }
    return `${_value}`.replace('.', ',');
  };

  const validateEmail = async (validate) => {
    setEmailValidating(true);

    var eMailSuffix = form.getFieldValue('eMailSuffix');
    var mail = validate + '@' + eMailSuffix;

    const { data } = await apiValidateEmail(mail);
    setEmailValidating(false);
    if (data) {
      if (data?.validEmail) {
        return Promise.resolve('validate Email');
      } else {
        return Promise.reject(t('users:form.validation.invalidEmail'));
      }
    } else {
      return Promise.reject(t('users:form.validation.invalidEmail'));
    }
  };

  const handleFinish: (value: AddEmployee) => void = async (
    value: AddEmployee
  ) => {
    setIsSaving(true);
    const newEMail = value.eMailPrefix + '@' + value.eMailSuffix;
    const dateOfEmployment = value.dateOfEmployment
      ? moment(value.dateOfEmployment)
      : undefined;
    const success = await onFinishCreate({
      ...value,
      eMail: newEMail,
      dateOfEmployment:
        dateOfEmployment?.toISOString(true).split('T')[0] ?? undefined,
    });
    if (success) {
      form.resetFields();
    }
    setIsSaving(false);
  };

  //#endregion

  //#region ------------------------------ Effects
  useEffect(() => {
    setDisableActionButton(
      !isFormChanged || isSaving || emailValidating || loadSuffixes
    );
  }, [
    isFormChanged,
    isSaving,
    emailValidating,
    loadSuffixes,
    setDisableActionButton,
  ]);

  useEffect(() => {
    dispatch(fetchTitleSuggestions());
  }, [dispatch]);

  useEffect(() => {
    if (userMe) {
      emptyEmployee.managerId = userMe.id;
    }
  }, [form, userMe]);

  useEffect(() => {
    setLoadSuffixes(true);
    const loadEmailSuffixes = async () => {
      const { data } = await apiFetchEmailSuffixes();
      if (data) {
        setEmailSuffixes(data);
        if (data?.length > 0) {
          emptyEmployee.eMailSuffix = data[0];
        }
      }
      setLoadSuffixes(false);
    };

    loadEmailSuffixes();
  }, [form]);

  useEffect(() => {
    if (!disableForm) {
      const loadConfigs = async () => {
        const { data } = await apiFetchConfigurations();
        if (data) setConfigurations(data);
      };
      loadConfigs();
    }
  }, [disableForm]);
  //#endregion

  return (
    <Form<AddEmployee>
      className={classes.root}
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      initialValues={emptyEmployee}
      scrollToFirstError
      onValuesChange={(__, allValues) => {
        const parsedInitialValues = parseFalsyValuesToUndefined(emptyEmployee);
        const reducedInitialValues =
          removeFalsyValuesFromObject(parsedInitialValues);

        const parsedValues = parseFalsyValuesToUndefined(allValues);
        const reducedValues = removeFalsyValuesFromObject(parsedValues);

        if (eqauls(reducedValues, reducedInitialValues)) {
          setisFormChanged(false);
        } else {
          setisFormChanged(true);
        }
      }}
    >
      <Flex.Column className={classes.column}>
        <div className={classes.scrollable}>
          <Flex.Row>
            <Flex.Item flex={1}>
              <div className={classes.headingFirst}>
                <Typography.Text type="secondary">
                  {t('hr:addEmployeeForm.pivot.general')}
                </Typography.Text>
              </div>
              <Flex.Row childrenGap={theme.spacing.small}>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'firstName'}
                    label={t('hr:addEmployeeForm.labels.firstName')}
                    colon={colon}
                    rules={[
                      {
                        required: true,
                        message: t(
                          'hr:addEmployeeForm.validation.missing.firstName'
                        ),
                      },
                    ]}
                  >
                    <Input disabled={disableForm} />
                  </Form.Item>
                </Flex.Column>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'lastName'}
                    label={t('hr:addEmployeeForm.labels.lastName')}
                    colon={colon}
                    rules={[
                      {
                        required: true,
                        message: t(
                          'hr:addEmployeeForm.validation.missing.lastName'
                        ),
                      },
                    ]}
                  >
                    <Input disabled={disableForm} />
                  </Form.Item>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row childrenGap={theme.spacing.small}>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'gender'}
                    label={t('hr:addEmployeeForm.labels.gender')}
                    colon={colon}
                    rules={[
                      {
                        required: true,
                        message: t(
                          'hr:addEmployeeForm.validation.missing.gender'
                        ),
                      },
                    ]}
                  >
                    <Select
                      disabled={disableForm}
                      options={[
                        {
                          value: 'male',
                          label: t('contacts:form.gender.male'),
                        },
                        {
                          value: 'female',
                          label: t('contacts:form.gender.female'),
                        },
                        {
                          value: 'diverse',
                          label: t('contacts:form.gender.diverse'),
                        },
                      ]}
                    />
                  </Form.Item>
                </Flex.Column>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'title'}
                    colon={colon}
                    label={t('hr:addEmployeeForm.labels.title')}
                  >
                    <AutoComplete
                      options={titleOptions || fallBackTitleOptions}
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                  </Form.Item>
                </Flex.Column>
              </Flex.Row>
              <Flex.Row childrenGap={theme.spacing.small}>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'officeId'}
                    label={t('hr:addEmployeeForm.labels.officeId')}
                    rules={[
                      {
                        required: true,
                        message: t(
                          'hr:addEmployeeForm.validation.missing.officeId'
                        ),
                      },
                    ]}
                  >
                    <OfficePicker disabled={disableForm} onlyInternal />
                  </Form.Item>
                </Flex.Column>
                <Flex.Column flex={1}>
                  <Form.Item
                    name={'managerId'}
                    label={t('hr:addEmployeeForm.labels.managerId')}
                  >
                    <ContactPicker
                      contactType="InternalContact"
                      disabled={disableForm}
                    />
                  </Form.Item>
                </Flex.Column>
              </Flex.Row>
            </Flex.Item>
          </Flex.Row>
          <Divider />
          <div className={classes.heading}>
            <Typography.Text type="secondary">
              {t('hr:addEmployeeForm.pivot.contactData')}
            </Typography.Text>
          </div>
          <Flex.Row childrenGap={theme.spacing.small}>
            <Flex.Column flex={1}>
              <Form.Item
                name={'phone'}
                label={t('hr:addEmployeeForm.labels.phone')}
                colon={colon}
                rules={[
                  {
                    message: t(
                      'hr:addEmployeeForm.validation.invalidPhoneNumber'
                    ),
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_PHONE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                  },
                ]}
              >
                <Input disabled={disableForm} />
              </Form.Item>
            </Flex.Column>
            <Flex.Column flex={1}>
              <Form.Item
                name={'cellphone'}
                label={t('hr:addEmployeeForm.labels.cellphone')}
                colon={colon}
                rules={[
                  {
                    message: t(
                      'hr:addEmployeeForm.validation.invalidPhoneNumber'
                    ),
                    pattern: configurations
                      ? configurations.find(
                          (config) =>
                            config.key === ConfigurationKeys.REGEX_PHONE
                        )
                        ? new RegExp(
                            configurations.find(
                              (config) =>
                                config.key === ConfigurationKeys.REGEX_PHONE
                            ).value
                          )
                        : defaultPhonePattern
                      : defaultPhonePattern,
                  },
                ]}
              >
                <Input disabled={disableForm} />
              </Form.Item>
            </Flex.Column>
          </Flex.Row>
          <Flex.Row
            childrenGap={theme.spacing.small}
            style={{ marginTop: theme.old.spacing.unit(3) }}
          >
            <Flex.Column flex={1}>
              <Form.Item
                name="eMailPrefix"
                label={t('hr:addEmployeeForm.labels.eMailPrefix')}
                validateTrigger="onBlur"
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t('projects:form.validation.missingEmailPrefix'),
                  },
                  () => ({
                    async validator(rule, value) {
                      await validateEmail(value);
                    },
                  }),
                ]}
              >
                <Input disabled={disableForm} />
              </Form.Item>
            </Flex.Column>
            <Flex.Column flex={1}>
              <Form.Item
                name="eMailSuffix"
                label={t('hr:addEmployeeForm.labels.eMailSuffix')}
                colon={colon}
                validateTrigger="onChange"
                rules={[
                  {
                    required: true,
                    message: t(
                      'hr:addEmployeeForm.validation.missing.emailSuffix'
                    ),
                  },
                ]}
              >
                <Select
                  className={classes.eMailSuffixSelect}
                  disabled={disableForm}
                >
                  {emailSuffixes.map((emailSuffix) => (
                    <Select.Option value={emailSuffix} key={emailSuffix}>
                      {emailSuffix}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Flex.Column>
          </Flex.Row>
          <Flex.Row childrenGap={theme.spacing.small}>
            <Flex.Column flex={1}>
              <Form.Item
                name={'templateContactId'}
                label={t('hr:addEmployeeForm.labels.templateContactId')}
                colon={colon}
                rules={[
                  {
                    required: true,
                    message: t(
                      'hr:addEmployeeForm.validation.missing.templateContactId'
                    ),
                  },
                ]}
              >
                <ContactPicker
                  contactType="InternalContact"
                  disabled={disableForm}
                />
              </Form.Item>
            </Flex.Column>
          </Flex.Row>
          <Divider />
          <div className={classes.heading}>
            <Typography.Text type="secondary">
              {t('hr:addEmployeeForm.pivot.contractData')}
            </Typography.Text>
          </div>
          <Flex.Row childrenGap={theme.spacing.small}>
            <Flex.Column flex={1}>
              <Form.Item
                name="formOfEmployment"
                label={t('hr:addEmployeeForm.labels.formOfEmployment')}
                colon={colon}
              >
                <Select<FormOfEmployment> disabled={disableForm}>
                  {allFormOfEmploymentTypes.map((type) => (
                    <Select.Option value={type} key={type}>
                      {t(`hr:formOfEmploymentTypes.${type}`)}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Flex.Column>
            <Flex.Column flex={1}>
              <Form.Item
                name="hoursPerWeek"
                label={t('hr:addEmployeeForm.labels.hoursPerWeek')}
                colon={colon}
              >
                <DebouncedInputNumber
                  disabled={disableForm}
                  formatter={formatter}
                  decimalSeparator=","
                />
              </Form.Item>
            </Flex.Column>
          </Flex.Row>
          <Flex.Row childrenGap={theme.spacing.small}>
            <Flex.Column flex={1}>
              <Form.Item
                name="dateOfEmployment"
                label={t('hr:addEmployeeForm.labels.dateOfEmployment')}
                colon={colon}
                className={classNames({
                  [classes.labelHidden]: isCalendarViewOpen,
                })}
                rules={[
                  {
                    required: true,
                    message: t(
                      'hr:addEmployeeForm.validation.missing.dateOfEmployment'
                    ),
                  },
                ]}
              >
                <CustomSingleDatePicker
                  id="add_personnelfile_id"
                  onFocusChange={setIsCalendarViewOpen}
                  focused={isCalendarViewOpen}
                  openDirection={'up'}
                  small={true}
                  regular={false}
                  twoMonths={false}
                  withFullScreenPortal={false}
                  daySize={30}
                  hideKeyboardShortcutsPanel={true}
                />
              </Form.Item>
            </Flex.Column>
            <Flex.Column flex={1}>
              <Form.Item
                name="holidayEntitlement"
                label={t('hr:addEmployeeForm.labels.holidayEntitlement')}
                colon={colon}
              >
                <DebouncedInputNumber
                  disabled={disableForm}
                  formatter={formatter}
                  decimalSeparator=","
                />
              </Form.Item>
            </Flex.Column>
          </Flex.Row>
          <Flex.Column flex={1}>
            <div
              className={classes.heading}
              style={{ marginTop: theme.old.spacing.unit(2) }}
            >
              <Typography.Text type="secondary">
                {t('hr:addEmployeeForm.labels.workingDays')}
              </Typography.Text>
            </div>
            <Flex.Row flex={1} gutter={rowGutter}>
              <Flex.Column flex={1}>
                <Form.Item
                  name="workingDayMonday"
                  label={t('hr:addEmployeeForm.labels.workingDayMonday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekdays}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Form.Item
                  name="workingDayTuesday"
                  label={t('hr:addEmployeeForm.labels.workingDayTuesday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekdays}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Form.Item
                  name="workingDayWednesday"
                  label={t('hr:addEmployeeForm.labels.workingDayWednesday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekdays}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Form.Item
                  name="workingDayThursday"
                  label={t('hr:addEmployeeForm.labels.workingDayThursday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekdays}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={1}>
                <Form.Item
                  name="workingDayFriday"
                  label={t('hr:addEmployeeForm.labels.workingDayFriday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekdays}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Flex.Column>
            </Flex.Row>
            <Flex.Row>
              <Flex.Column flex={1}>
                <Form.Item
                  name="workingDaySaturday"
                  label={t('hr:addEmployeeForm.labels.workingDaySaturday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekend}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Flex.Column>
              <Flex.Column flex={4}>
                <Form.Item
                  name="workingDaySunday"
                  label={t('hr:addEmployeeForm.labels.workingDaySunday')}
                  colon={colon}
                  valuePropName="checked"
                  className={classes.checkboxWeekend}
                >
                  <Checkbox disabled={disableForm} />
                </Form.Item>
              </Flex.Column>
            </Flex.Row>
          </Flex.Column>
        </div>
      </Flex.Column>
    </Form>
  );
};

export default EmployeeForm;
