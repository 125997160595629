import { makePrioStyles } from '../../theme/utils';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';

import {
  FilterSelectPicker,
  Button,
  HistoryItem,
} from '@prio365/prio365-react-library';
import classNames from 'classnames';
import { FilterBarHistoryDrawer } from './FilterBarHistoryDrawer';
import useFilterContext from './hooks/useFilterContext';
import { useTranslation } from 'react-i18next';
import FilterPickerSkeleton from './FilterPickerSkeleton';

import { useSelector } from 'react-redux';
import { getIsFetchingSearchConfig } from '../../apps/main/rootReducer';
import { FilterConfig, PickerStrings, SearchOperation } from './types';
import DynamicFilterContainer from './FilterBarDynamicFilter';
import { calculatePickerStringsBasedOnSearchString } from './utils';

const CLASS_PREFIX = 'prio-filter-bar';

export const FILTER_DATA_LIST_CLASS_PREFIX = 'prio-filter-data-list';

export const ALLOWED_METHODS_HIERACHY: SearchOperation[] = ['like', 'eq', 'in'];

const useStyles = makePrioStyles((theme) => ({
  bar: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: 'fit',
    gap: `${theme?.old.spacing?.baseSpacing}px`,
    backgroundColor: `${theme?.old.palette?.chromaticPalette.white}`,
    marginBottom: theme.spacing.large,
  },
  filterContainerOuter: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    position: 'relative',
    flexGrow: 1,
    maxHeight: 136,
    overflowY: 'auto',
  },
  filterContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: `${theme?.old?.spacing?.baseSpacing}px`,
    overflowX: 'scroll',
    overflowY: 'hidden',
  },
  filters: {
    display: 'contents',
  },
  actionsContainer: {
    marginTop: 4,
    gap: `${theme?.old?.spacing?.baseSpacing * 2}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  resetFilterButton: {
    '& > svg': {
      color: theme?.old?.palette?.chromaticPalette?.red,
    },
    '&:hover': {
      backgroundColor: `${theme?.old?.palette?.chromaticPalette?.red}20`,
    },
  },
  fullHeight: {
    height: '100%',
  },
}));

export interface FilterBarRef {
  handleResetFilterParameterFromOutside: () => void;
  setSearchString: (searchString: string) => void;
  fetchSearch: (searchString: string) => void;
}

interface FilterBarProps {
  /**
   * The class name of the filter bar
   */
  className?: string;
  /**
   * Everytime the filter state changes, this function will be called with the new search string
   */
  onChange?: (searchString: string) => void;
  /**
   * Filter pickers that shall be hidden
   */
  hiddenPickers?: string[];
  /**
   * Whether to allow the user to clear the search
   * @default true
   */
  allowUserToClearSearch?: boolean;
  /**
   * Function to call when setting default values
   * @param defaultSearchParameters The default search parameters
   * @returns
   */
  onSetDefaultValues?: (defaultSearchParameters: string) => void;
}

export const FilterBar = forwardRef(
  (props: FilterBarProps, ref: React.Ref<FilterBarRef>) => {
    //#region -------------------------------- Defaults
    const {
      className = '',
      onChange = () => {},
      hiddenPickers = [],
      allowUserToClearSearch = true,
      onSetDefaultValues,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation();
    //#endregion

    //#region -------------------------------- States / Selectors
    const containerRef = useRef(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [openDrawer, setOpenDrawer] = useState<boolean>(false);

    const [selectedPickers, setSelectedPickers] = useState<string[]>([]);

    const {
      fetchSearch,
      isFetching: isFetchingResults,
      searchType,
      isError,
      defaultSearchParameters,
      disableURLQuery,
      searchFilterConfig,
      searchString: _searchString,
    } = useFilterContext();

    const [searchString, setSearchString] = useState<string>(_searchString);

    const cleanSearchString = useMemo(() => {
      const searchStringSplit =
        searchString?.split('&')?.map((s) => s?.trim()) || [];
      const newSearchString = searchStringSplit
        .filter((string) => string.length > 0)
        .join(' & ');
      return newSearchString;
    }, [searchString]);

    const searchStringRef = useRef<string>(cleanSearchString);

    const [lastSearchedCleanSearchString, setLastSearchedCleanSearchString] =
      useState<string>(_searchString);

    const isFetchingConfig = useSelector(getIsFetchingSearchConfig);

    const { filters, requiredFilters, optionalFilters } = useMemo<{
      filters: FilterConfig[];
      requiredFilters: FilterConfig[];
      optionalFilters: FilterConfig[];
    }>(() => {
      const filters = searchFilterConfig?.searchableParameters;
      const displayedFilters = filters?.filter(
        (f) => !hiddenPickers?.includes(f?.parameterName)
      );
      const requiredFilters = displayedFilters?.filter((f) => f?.showAlways);
      const optionalFilters = displayedFilters?.filter((f) => !f?.showAlways);
      return { filters, requiredFilters, optionalFilters };
    }, [searchFilterConfig, hiddenPickers]);

    const filtersForFilterPicker = useMemo(() => {
      return (optionalFilters ?? [])
        .sort((a, b) => a.sortKey - b.sortKey)
        .map((f) => ({
          value: f.parameterName,
          label: f.parameterNameTranslated || f.parameterName,
          searchValue: f.parameterNameTranslated || f.parameterName,
        }));
    }, [optionalFilters]);

    const addedPickerNames = useMemo(() => {
      const pickers = selectedPickers.filter(
        // only add optional pickers to filter bar
        (picker) => optionalFilters?.find((f) => f?.parameterName === picker)
      );
      return pickers;
    }, [selectedPickers, optionalFilters]);
    //#endregion

    //#region ------------------------------ Functions / Handlers
    const deletePickerStrings = useCallback((parameterName: string) => {
      setSearchString((searchString) => {
        const searchStringSplit = (searchString?.split('&') || []).map((s) =>
          s.trim()
        );
        const newSearchString = searchStringSplit
          .filter((string) => !string.split(' ')?.[0]?.includes(parameterName))
          .join(' & ');
        return newSearchString;
      });
    }, []);

    // want multiple values? separate value by comma
    const setPickerStrings = useCallback(
      (parameterName: string, values: PickerStrings[]) => {
        setSearchString((searchString) => {
          const searchStringSplit = (searchString?.split('&') || []).map((s) =>
            s.trim()
          );
          const newSearchString =
            searchStringSplit.filter(
              (string) => !string.split(' ')?.[0]?.includes(parameterName)
            ) || [];
          const newValues =
            values
              ?.filter(
                (v) =>
                  v.value !== null &&
                  v.value !== undefined &&
                  v.value !== 'null'
              )
              ?.map(
                (value) =>
                  `${parameterName} ${value.method} '${value.value
                    .split(',')
                    .join("','")}'`
              ) || [];
          const combinedSearchString = [...newSearchString, ...newValues]
            .filter((s) => s?.length > 0)
            .join(' & ');

          return combinedSearchString;
        });
      },
      []
    );

    const getPickerStrings: (parameterName: string) => PickerStrings[] =
      useCallback(
        (parameterName: string) => {
          return calculatePickerStringsBasedOnSearchString(
            parameterName,
            searchString
          );
        },
        [searchString]
      );

    const handleDeleteFilterPickers = useCallback(
      (addedPickerNames: string[]) => {
        var notAddedPickers =
          optionalFilters?.filter(
            (f) => !addedPickerNames?.includes(f?.parameterName)
          ) || [];
        if (notAddedPickers?.length > 0) {
          notAddedPickers.forEach((f) => {
            deletePickerStrings(f?.parameterName);
          });
        }
      },
      [optionalFilters, deletePickerStrings]
    );

    const handleSetPickers = useCallback(
      (searchString: string) => {
        // use searchFilterConfig object to prevent infinite loop
        const optionalFilters =
          searchFilterConfig?.searchableParameters?.filter(
            (f) => !f?.showAlways
          );

        const _pickers = searchString
          .split('&')
          .map((s) => s.trim())
          .map((s) => s.split(' ')[0])
          .filter((s) => optionalFilters?.find((op) => op.parameterName === s));
        const _uniquePickers = Array.from(new Set(_pickers));
        const pickers = searchString.length > 0 ? _uniquePickers : [];

        setSelectedPickers(pickers);
        return searchString.length > 0 ? pickers : [];
      },
      [searchFilterConfig?.searchableParameters]
    );

    const handleFetchSearch = useCallback(
      (searchString: string) => {
        setLastSearchedCleanSearchString(searchString);
        fetchSearch(searchString);
        handleSetPickers(searchString);
      },
      [fetchSearch, handleSetPickers]
    );

    const handleFilterFromHistory = useCallback(
      (historyItem: HistoryItem) => {
        const searchString = historyItem?.searchString || '';

        // addOptionalFilters based on search string (ignore already added pickers)
        const searchStringSplit =
          searchString?.split('&')?.map((s) => s?.trim()) || [];
        setSelectedPickers((selectedPickers) => {
          const newPickers = searchStringSplit.map(
            (string) => string.split(' ')?.[0]
          );

          const bothPickers = [...selectedPickers, ...newPickers];
          const uniqueNewPickers = Array.from(new Set(bothPickers));
          handleDeleteFilterPickers(uniqueNewPickers);
          return uniqueNewPickers;
        });

        setSearchString(searchString);

        setOpenDrawer(false);
        handleFetchSearch(historyItem?.searchString);
      },
      [handleFetchSearch, handleDeleteFilterPickers]
    );

    const handleClearSearch = useCallback(() => {
      // when hidden pickers are set for which default values exist, set those default values in search string
      const defaultSearchParams = defaultSearchParameters.split(' & ');
      let value = '';
      for (let i = 0; i < hiddenPickers.length; i++) {
        const searchValue = defaultSearchParams.find((element) =>
          element.includes(hiddenPickers[i])
        );

        value = `${value.length > 0 ? value + ' & ' : ''}${searchValue}`;
      }

      setSearchString(value);
      setLastSearchedCleanSearchString(value);
      handleFetchSearch(value);
      handleDeleteFilterPickers([]);
    }, [
      handleDeleteFilterPickers,
      handleFetchSearch,
      hiddenPickers,
      defaultSearchParameters,
    ]);

    const handleResetFilterParameterFromOutside = useCallback(() => {
      setSearchString('');
      setSelectedPickers([]);
      setLastSearchedCleanSearchString('');
    }, [setSelectedPickers]);

    const handleSetDefaultValues = useCallback(() => {
      if (onSetDefaultValues) {
        onSetDefaultValues(defaultSearchParameters);
      }
      setSearchString(defaultSearchParameters);
      handleFetchSearch(defaultSearchParameters);
    }, [defaultSearchParameters, handleFetchSearch, onSetDefaultValues]);

    //#endregion

    //#region ------------------------------ Effects
    useEffect(() => {
      setSearchString(_searchString);
      setLastSearchedCleanSearchString(_searchString);
      handleSetPickers(_searchString ?? '');
    }, [_searchString, handleSetPickers]);

    useEffect(() => {
      if (!disableURLQuery) {
        setSearchParams((searchParams: any) => {
          if (selectedPickers?.length <= 0)
            searchParams.delete('pickedFilters');
          else searchParams.set('pickedFilters', selectedPickers.join(','));
          return searchParams;
        });
      }
    }, [selectedPickers, disableURLQuery, setSearchParams]);

    useEffect(() => {
      if (!disableURLQuery) {
        setSearchParams((searchParams: any) => {
          if (
            lastSearchedCleanSearchString?.length <= 0 ||
            lastSearchedCleanSearchString === null
          ) {
            searchParams.delete('s');
          } else {
            searchParams.set('s', lastSearchedCleanSearchString);
          }

          return searchParams;
        });
      }
    }, [lastSearchedCleanSearchString, disableURLQuery, setSearchParams]);
    useEffect(() => {
      searchStringRef.current = searchString;
      onChange(searchString);
    }, [searchString, onChange]);

    useImperativeHandle(ref, () => ({
      handleResetFilterParameterFromOutside,
      setSearchString,
      fetchSearch: handleFetchSearch,
    }));
    //#endregion
    return (
      <div className={classNames(className, CLASS_PREFIX, classes?.bar)}>
        <div className={classes?.filterContainerOuter}>
          <div
            id="filtersContainer"
            ref={containerRef}
            className={classNames(classes?.filterContainer, 'scrollbar-hide')}
          >
            <div id="filters" className={classes?.filters}>
              {!filters?.[0] ? (
                <>
                  <FilterPickerSkeleton />
                  <FilterPickerSkeleton />
                  <FilterPickerSkeleton />
                </>
              ) : (
                <>
                  {requiredFilters
                    .sort((a, b) => b?.sortKey - a?.sortKey)
                    .map((filter, i) => (
                      <DynamicFilterContainer
                        key={filter?.parameterName + i}
                        deletePickerStrings={deletePickerStrings}
                        setPickerStrings={setPickerStrings}
                        pickerStrings={getPickerStrings(filter?.parameterName)}
                        filter={filter}
                      />
                    ))}
                  {addedPickerNames?.map((name, i) => {
                    const filter = optionalFilters.find(
                      (f) => f?.parameterName === name
                    );

                    return (
                      <DynamicFilterContainer
                        key={filter?.parameterName + i}
                        deletePickerStrings={deletePickerStrings}
                        setPickerStrings={setPickerStrings}
                        pickerStrings={getPickerStrings(filter?.parameterName)}
                        filter={filter}
                      />
                    );
                  })}
                  {optionalFilters?.length > 0 && (
                    <div
                      onContextMenu={(e) => {
                        e.preventDefault();
                        setSelectedPickers([]);
                        handleDeleteFilterPickers([]);
                      }}
                    >
                      <FilterSelectPicker
                        value={addedPickerNames || []}
                        onChange={(names) => {
                          setSelectedPickers(names);
                          handleDeleteFilterPickers(names);
                        }}
                        filters={filtersForFilterPicker}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div
          className={classNames('prio-filter-bar-actions', classes.fullHeight)}
        >
          <div className={classes?.actionsContainer}>
            {allowUserToClearSearch && (
              <Button
                disabled={
                  isFetchingConfig ||
                  (selectedPickers?.length <= 0 && searchString?.length <= 0)
                }
                key={searchParams.size}
                iconProp={['fal', 'trash']}
                className={
                  searchParams.size <= 0 ? undefined : classes.resetFilterButton
                }
                onClick={handleClearSearch}
                type="default"
                tooltip={t('components:filter.actions.clear')}
              ></Button>
            )}
            <Button
              disabled={
                isFetchingConfig || searchString === defaultSearchParameters
              }
              iconProp={['fal', 'rotate-left']}
              onClick={handleSetDefaultValues}
              type="default"
              tooltip={t('components:filter.actions.setDefault')}
            ></Button>
            <Button
              disabled={isFetchingConfig}
              iconProp={['fal', 'folder-bookmark']}
              onClick={() => setOpenDrawer(true)}
              type="default"
              tooltip={t('components:filter.actions.showHistory')}
            ></Button>
            <Button
              loading={isFetchingResults || isFetchingConfig}
              disabled={(isFetchingResults || isFetchingConfig) && !isError}
              iconProp={['fal', 'magnifying-glass']}
              tooltip={t('components:filter.actions.search')}
              onClick={() => handleFetchSearch(cleanSearchString)}
              type={
                cleanSearchString === lastSearchedCleanSearchString
                  ? 'default'
                  : 'primary'
              }
            ></Button>
          </div>
          <FilterBarHistoryDrawer
            onFilterAgain={handleFilterFromHistory}
            visible={openDrawer}
            filters={filters}
            setVisible={setOpenDrawer}
            searchType={searchType}
          />
        </div>
      </div>
    );
  }
);
